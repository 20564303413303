import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>
         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 <br /><h2> B.S. Computer Engineering, Math Minor </h2>
                 <h1> LeTourneau University, Class of 2020 </h1>
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">

          <br /><h2>Systems Analyst</h2>
          <h1>Fresno County</h1>
          <ul>
          <li>March 2021-present</li><br />
          <li>	Develop and implement software to improve security, effeciency, and productivity</li>
          <li>	Work with stakeholders to identify needs, and ensure solutions are effective</li>
          </ul>


          <br /><h2> Hardware Design Engineer</h2>
          <h1> LEGRange</h1>
          <ul>
          <li>Summer 2020</li><br />
          <li>Designed First-of-its-kind GPS testing equipment using Altium</li>
          <li>	Worked with Manufacturer to fabricate and assemble test equipment</li>
          <li>	Advised on software integration to best utilize hardware</li>
          <li>	Troubleshooted and diagnosed problems with existing hardware</li>
          <li>	Trained and advised future team members on project</li>
          <li>	Assisted with Linux Kernel and driver development</li>
          <li>	Developed and designed glue logic and bit packing system on CPLD using VHDL</li>
          </ul>

          <br /><h2> Software/Hardware Validation </h2>
          <h1> Eric Michael Services </h1>
          <ul>
          <li>Summer 2015 </li>< br />
          <li>Tested and debugged software for Intercept Illuminator to ensure its safety in the field</li>
          <li>Used QNX, VBA, and Excel to develop automated testing processes </li>
          <li>Wrote and performed tests to assist in FDA certification</li>
          </ul>


          <br /><h2> Lead Technician </h2>
          <h1> LeTourneau University IT </h1>
          <ul>
          <li> January 2015 - present </li> <br />
          <li>Train and Hire new student workers</li>
          <li>Develop and maintain processes to effectively utilize student team</li>
          <li>Solve Long-term IT projects</li>
          <li>Resolve day-to-day computer issues around campus</li>
          </ul>
        </div>
    </div>

   </section>
    );
  }
}

export default Resume;
