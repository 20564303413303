import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
//      var name = this.props.data.name;
//      var profilepic= "images/"+this.props.data.image;
//      var bio = this.props.data.bio;
//      var street = this.props.data.address.street;
//      var city = this.props.data.address.city;
//      var state = this.props.data.address.state;
//      var zip = this.props.data.address.zip;
//      var phone= this.props.data.phone;
//      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
         <h2>Skills:</h2>
         <ul>
         <li>Java, Python, C++, VBA, VHDL, Assembly</li>
         <li>Windows, Linux, QNX</li>
         <li>Arch, Debian, Ubuntu, Fedora</li>
         <li>Altium, KiCAD, PCB artist</li>
         <li>Embedded systems (Raspberry Pi, Arduino, ATtiny and other low power architectures)</li>
         <li>CPLD and FPGA design</li>
         <li>FCC Broadcast regulations</li>
 </ul>
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>Hello!
            I'm a recent graduate from LeTourneau University. I have a degree in Computer Engineering, and I love embedded systems!
            While hardware and low-level programming like C and VHDL are my strongest skills, I am more than capable of higher-level languages like Java, Ruby, or Python.</p>
            <p>Outsite of work and freelancing, I enjoy tinkering with lots of different technologies. Below you will see a selection of projects I have worked on, both privately and professionally. </p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">



                <span>LinkedIn: <a href="https://www.linkedin.com/in/mark-hegreberg-8096a2a4/">Mark Hegreberg</a></span><br />
                <span>Email: <a href="mailto:Mark@hegreberg.io">Mark@hegreberg.io</a></span><br />
                <span>Twitter: <a href="https://twitter.com/potatoes_twice">@potatoes_twice</a></span><br />
					   </p>
               </div>

               <div className="columns download">
                  <p>
                     <a href="Resume.pdf" download="MarkHegreberg" className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
