import React, { Component } from 'react';
import $ from 'jquery'

import Header from './components/header/header';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Portfolio from './components/portfolio/portfolio';
import Testimonials from  './components/testimonials/testimonials';
//import ContactUs from './components/contactus/contactus';
import Footer from './components/footer/footer';
class App extends Component {

  getResumeData(){
     $.ajax({
       url:'/resumeData.json',
       dataType:'json',
       cache: false,
       success: function(data){
         this.setState({resumeData: data});
       }.bind(this),
       error: function(xhr, status, err){
         console.log(err);
         alert(err);
       }
     });
   }

   componentDidMount(){
     this.getResumeData();
   }

  render() {
    return (
      <div className="App">
        <Header />
        <About />
        <Resume />
        <Portfolio />
        
        <Footer />
      </div>
    );
  }
}export default App;
