import React, { Component } from 'react';

class Portfolio extends Component {
  render() {

    if(this.props.data){
      var projects = this.props.data.projects.map(function(projects){
        var projectImage = 'images/portfolio/'+projects.image;
        return <div key={projects.title} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={projects.url} title={projects.title}>
               <img alt={projects.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{projects.title}</h5>
                     <p>{projects.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="portfolio">

      <div className="row">

         <div className="twelve columns collapsed">

            <h1>Check Out Some of My Projects!</h1>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">

            <ul>
            <h2>LEGRange Adversarial GPS Test Range | Sandia National Labs</h2>
              <ul>
              <li> Developing a First of its kind adversarial GPS test range</li>
              <li> Broadcasting GPS on a safe to test ISM frequency to test an array of attacks</li>
              <li> Used Altium to design RF front-ends to receive and transmit authentic and emulated GPS signals</li>
              <li> Designed Pseudo-satellites for safe adversarial GPS testing</li>
              <li> Utilized analog RF filter design and an SDR to clean and process signal</li>
              <li> Built test environment in Linux Using GRID and Ettus SDRs</li>
              <li> Used Git to manage work between multiple engineers, and track progress</li>
              <li> Used Swim Lanes to assign tasks and manage workflow, as well as keep team member accountable</li>
              <li> Responsible for assigning junior member tasks on project</li>
              </ul>
            <h2>Water Pump Monitor</h2>
              <ul>
              <li> Created a low-power water pump monitoring device to control treatment pumps With an Arduino Microcontroller</li>
              <li> Monitored flow of proprietary bio-cleaning substance for city water systems</li>
              <li> Reported issues cellularly to technicians using SimCom 5320A cellular transmitter</li>
              <li> Designed logging tool to track issues and report outages</li>
              </ul>
            <h2>Validation and Verification of Medical Device</h2>
              <ul>
              <li> Validated and prepared software tests for blood treatment device to assist with FDA certification using Excel, VBA, and C++</li>
              <li> Repaired and developed automated testing fixture to interface with new generation of QNX based device</li>
              <li> Tested device against extreme electrical spikes, power outages, physical damage, and user error</li>
              </ul>
            <h2>Laser Line Studfinder</h2>
              <ul>
              <li> Designed a studfinder that deployed small laser-line devices to mark full length of stud using an Arduino and capacitive sensors</li>
              <li> Built prototype using 3D Printing</li>
              <li> Used iterative design process to determine final product.</li>
              </ul>
            <h2>Bovine Egress Sensor</h2>
              <ul>
              <li> Built outdoor-safe motion sensor to monitor cows entering and leaving pasture with a finite state machine and ultrasonic sensors</li>
              <li> Integrated with fly-repellant system using relays to control spray flow</li>
              <li> Powered system with commercial solar-battery array for ruggedness and reliability in field</li>
              </ul>
            <h2>Accessible Keyboard</h2>
              <ul>
              <li> Prototyped a keyboard accessible to people of low fine motor skills to assist in education</li>
              <li> Used large boxes and motion sensors to give clear and easy method of entering input, regardless of motor skills</li>
              </ul>
            </ul>
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio;
